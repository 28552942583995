import React, { useContext, useEffect } from 'react'
import AboutUs from '../../components/complex/AboutUs'
import Advantages from '../../components/complex/Advantages'
import Excellences from '../../components/complex/Excellences'
import FaqComponent from '../../components/complex/FaqComponent'
import OurBlog from '../../components/complex/OurBlog'
import OurBusiness from '../../components/complex/OurBusiness'
import OurTeam from '../../components/complex/OurTeam'
import ReviewSection from '../../components/complex/ReviewSection'
import PageTop from '../../components/simple/PageTop'
import useFetchItems from '../../hooks/useFetchItems'
import AppServices from '../../services/general/appServices'
import { useNavigate, useParams } from 'react-router-dom'
import BannerSection from '../../components/simple/BannerSection'
import BannerSection2 from '../../components/simple/BannerSection2'
import Newsletter from '../../components/complex/Newsletter'
import DayOffer from '../../components/complex/DayOffer'
import ProductsGrid from '../../components/complex/ProductsGrid'
import TextComponent from '../../components/simple/TextComponent'
import Partners from '../../components/complex/Partners'
import BestDeals from '../../components/complex/BestDeals'
import Form from '../../components/complex/Form'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'

const SamplePage = observer(() => {
    const navigate = useNavigate();
    const {app} = useContext(Context);
    const{lang: lang2} = useParams();
    const {slug} = useParams();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2){
                navigate(`/${app.lang}/page/${slug}`);
            }
        }
    }, [app.lang])

    const {
        items,
        loading,
    } = useFetchItems(AppServices.page, slug)

  return (
    <>
    {loading ?
        <div className="fullpage-loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        :
        items && <div>
            <PageTop item={items}/>
            {items.description &&
                <section className='blog-section section-b-space'>
                    <div className="container-fluid-lg">
                        <div dangerouslySetInnerHTML={{__html: items.description}}></div>
                    </div>
                </section>
            }
            {items.elements?.map((elem, index) =>
                <div key={index}>
                    {elem.type === "about" ?
                        <AboutUs elem={elem}/>
                    :
                    elem.type === "advantages" ?
                        <Advantages item={elem}/>
                    :
                    elem.type === "workers" ?
                        <OurTeam elem={elem}/>
                    :
                    elem.type === "reviews" ?
                        <ReviewSection elem={elem}/>
                    :
                    elem.type === "faq" ?
                        <FaqComponent elem={elem}/>
                    :
                    elem.type === "advantages-2" ?
                        <OurBusiness elem={elem}/>
                    :
                    elem.type === "steps" ?
                        <Excellences elem={elem}/>
                    :
                    elem.type === "articles" ?
                        <OurBlog elem={elem}/>
                    :
                    elem.type === "advertising-banner-2" ?
                        Number(elem.banner_type) === 1 ?
                            <BannerSection item={elem}/>
                            :
                        Number(elem.banner_type) === 2 ?
                            <BannerSection2 item={elem}/>
                            :
                        Number(elem.banner_type) === 3 ?
                            <Newsletter item={elem}/>
                            :
                            <></>
                        :
                    elem.type === "products" ?
                        elem.products_type === "recommended" ?
                            <DayOffer item={elem}/>
                            :
                            <ProductsGrid item={elem}/>
                        :
                    elem.type === "text-component" ?
                        <TextComponent elem={elem}/>
                    :
                    elem.type === "opening-slider" ?
                        <BestDeals elem={elem}/>
                        :
                    elem.type === "partners" ?
                        <Partners elem={elem}/>
                        :
                    elem.type === "form" ?
                        <Form item={elem}/>
                        :
                        <></>
                    }
                </div>
            )}
        </div>
    }</>
  )
})

export default SamplePage