import React, { useContext } from 'react'
import { useEffect, useState} from 'react'
import AddToBasket from '../simple/AddToBasket'
import RightInfo from '../simple/RightInfo'
import ProductSlider from './ProductSlider'
import Utils from '../../services/utils'
import UserServices from '../../services/general/userServices'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'
import { NavLink, useNavigate } from 'react-router-dom'

const ProductLeftSidebar = observer(({item, fetchInfo}) => {
	const [images, setImages] = useState();
	const [features, setFeatures] = useState();
    const [success, setSuccess] = useState();
    const navigate = useNavigate();

    const {app, user} = useContext(Context);

    const [price, setPrice] = useState();
    const [oldPrice, setOldPrice] = useState();

	useEffect(() => {
		if(item){
			setImages(item.gallery)
			setFeatures(item.features)
		}
	}, [item])

    useEffect(() => {
        setSuccess(item.is_favorite)
    }, [item])

    const addToWishlist = async () => {
        let temp = success;
        setSuccess(!success);
        const newData = {
            uid: user.wishlistUid ? user.wishlistUid : null,
            product_id: item.id
        }

        const res = await UserServices.favorites.toggle(newData);

        if (res.statusCode === 200) {
            if(!user.wishlistUid){
                user.setWishlistUid(res.content.uid);
            }
        }else{
            setSuccess(temp)
        }
    }

  return (
	<section className="product-section">
		<div className="container-fluid-lg">
            <div className="back-button2" onClick={() => navigate(-1)}>
                <span><i className="fa-solid fa-arrow-left"></i>{app.localizationsItems?.back}</span>
            </div>
			<div className="row">
				<div className="col-xxl-9 col-xl-8 col-lg-7 wow fadeInUp">
					<div className="row g-4">
						<div className="col-xl-6 wow fadeInUp">
							<ProductSlider images={images} image={item.image}/>
						</div>
						<div className="col-xl-6 wow fadeInUp">
							<div className="right-box-contain">
                                {item.discount && 
                                    <h6 className="offer-top mr-3">{app.localizationsItems?.discount} {Utils.numberWithSpaces(item.discount.number)} {item.discount.type == 1 ? '%' : ''}</h6>
                                }
                                {item.bonus && 
                                    <h6 className="offer-top offer-bonus mr-3">{app.localizationsItems?.bonuses} {Utils.numberWithSpaces(item.bonus.number)} {item.bonus.type == 1 ? '%' : ''}</h6>
                                }
                                {item.additional_product && 
                                    <h6 className="offer-top offer-present">{app.localizationsItems?.gift} <i className='fa fa-gift'></i></h6>
                                }
								<h2 className="name">{item.title}</h2>
								<div className="price-rating">
									<h3 className="theme-color price">
                                        {!price ?
                                            <>{app.localizationsItems?.not_specified}</>
                                            :
                                            <>{Utils.numberWithSpaces(price)} {app.localizationsItems?.currency} </>
                                        }
                                        {oldPrice ? <del className="text-content"> {Utils.numberWithSpaces(oldPrice)} {app.localizationsItems?.currency}</del> : <></>}
                                    </h3>
								</div>
                                <div className='product-card__availability mb-2'>
                                    {app.localizationsItems?.availability}: {item.shipment &&
                                        <>
                                            {
                                                item.shipment.slug === "available" ?
                                                    <span className="text-success">{item.shipment.name}</span>
                                                : item.shipment.slug === "on_wait" ?
                                                    <span className="text-yellow">{item.shipment.name}</span>
                                                :
                                                    <span className="text-danger">{item.shipment.name}</span>
                                            }
                                        </>
                                    }
                                </div>
								<div className="procuct-contain">
									<p>{item.subtitle}</p>
								</div>
								<AddToBasket item={item} price={price} setPrice={setPrice} setOldPrice={setOldPrice}/>

								{app.userCabinet && <div className="buy-box" onClick={addToWishlist}>
                                    <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg" 
                                        className={success ? 'heart-svg heart-success' : 'heart-svg'}
                                        >
                                        <path d="M6.15 1C3.36914 1 1 3.0793 1 5.7798C1 7.64275 1.87235 9.21118 3.02849 10.5104C4.18066 11.8052 5.66714 12.892 7.01097 13.8016L9.32959 15.3711C9.58351 15.543 9.91649 15.543 10.1704 15.3711L12.489 13.8016C13.8329 12.892 15.3193 11.8052 16.4715 10.5104C17.6277 9.21118 18.5 7.64275 18.5 5.7798C18.5 3.0793 16.1309 1 13.35 1C11.9165 1 10.6552 1.67214 9.75 2.54183C8.84484 1.67214 7.58347 1 6.15 1Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
									<span>{app.localizationsItems?.add_to_favorites}</span>
								</div>}

								{features?.length >= 1 && <div className="pickup-box">
									<div className="product-info">
										<ul className="product-info-list product-info-list-2">
											{features.map((el, index) =>
												<li key={index}>{el.title} : {el.value}</li>
											)}
										</ul>
									</div>
								</div>}
                                {item.additional_product &&
                                    <NavLink to={`/${app.lang}/products/${item.additional_product.slug}`} className='product-present'>
                                        <img src={item.additional_product.preview_image} alt="" />
                                        <div>
                                            <div className='product-present-title'>{app.localizationsItems?.as_gift}</div>
                                            <div>{item.additional_product.title}</div>
                                        </div>
                                    </NavLink>
                                }
							</div>
						</div>
					</div>
				</div>

				<div className="col-xxl-3 col-xl-4 col-lg-5 d-none d-lg-block wow fadeInUp">
					<RightInfo/>
				</div>
			</div>
		</div>
	</section>
  )
})

export default ProductLeftSidebar