import ForgotPassword from "../pages/auth/ForgotPassword";
import LogIn from "../pages/auth/LogIn";
import Registration from "../pages/auth/Registration";
import Basket from "../pages/basket/Basket";
import Checkout from "../pages/basket/Checkout";
import OrderError from "../pages/basket/OrderError";
import OrderSuccess from "../pages/basket/OrderSuccess";
import ArticleDetail from "../pages/blog/ArticleDetail";
import ArticlesPage from "../pages/blog/ArticlesPage";
import Error from "../pages/error/Error";
import EventDetail from "../pages/events/EventDetail";
import EventsPage from "../pages/events/EventsPage";
import MainPage from "../pages/main/MainPage";
import Catalog from "../pages/products/Catalog";
import ProductDetail from "../pages/products/ProductDetail";
import ProductsPage from "../pages/products/ProductsPage";
import ProjectDetail from "../pages/projects/ProjectDetail";
import ProjectsPage from "../pages/projects/ProjectsPage";
import SamplePage from "../pages/sample/SamplePage";
import SearchPage from "../pages/sample/SearchPage";
import WishListPage from "../pages/sample/WishListPage";
import ServicesDetail from "../pages/services/ServicesDetail";
import ServicesPage from "../pages/services/ServicesPage";
import User from "../pages/user/User";
import { BASKET_PAGE_ROUTE, BLOG_DETAIL_PAGE_ROUTE, BLOG_PAGE_ROUTE, CATALOG_CATEGORY_PAGE_ROUTE, CATALOG_PAGE_ROUTE, CHECKOUT_PAGE_ROUTE, ERROR_PAGE_ROUTE, EVENT_DETAIL_PAGE_ROUTE, EVENTS_PAGE_ROUTE, FORGOT_PASSWORD_ROUTE, LOGIN_PAGE_ROUTE, MAIN_PAGE_ROUTE, ORDER_ERROR_PAGE_ROUTE, ORDER_SUCCESS_PAGE_ROUTE, PRODUCTS_DETAIL_PAGE_ROUTE, PRODUCTS_PAGE_ROUTE, PROJECTS_DETAIL_PAGE_ROUTE, PROJECTS_PAGE_ROUTE, REGISTRATION_PAGE_ROUTE, SAMPLE_PAGE_SOUTE, SEARCH_PAGE_ROUTE, SERVICES_DETAIL_PAGE_ROUTE, SERVICES_PAGE_ROUTE, USER_DASHBOARD_PAGE_ROUTE, USER_ORDER_ROUTE, USER_PAGE_ROUTE, WISHLIST_ROUTE } from "./Constants";

export const publicRoutes = [
    {
        path: MAIN_PAGE_ROUTE,
        Component: MainPage
    },
    {
        path: '/',
        Component: MainPage
    },
    {
        path: PRODUCTS_PAGE_ROUTE,
        Component: ProductsPage
    },
    {
        path: PRODUCTS_DETAIL_PAGE_ROUTE,
        Component: ProductDetail
    },
    {
        path: BLOG_PAGE_ROUTE,
        Component: ArticlesPage
    },
    {
        path: BLOG_DETAIL_PAGE_ROUTE,
        Component: ArticleDetail
    },
    {
        path: BASKET_PAGE_ROUTE,
        Component: Basket
    },
    {
        path: CHECKOUT_PAGE_ROUTE,
        Component: Checkout
    },
    {
        path: ORDER_SUCCESS_PAGE_ROUTE,
        Component: OrderSuccess
    },
    {
        path: LOGIN_PAGE_ROUTE,
        Component: LogIn
    },
    {
        path: REGISTRATION_PAGE_ROUTE,
        Component: Registration
    },
    {
        path: FORGOT_PASSWORD_ROUTE,
        Component: ForgotPassword
    },
    {
        path: ERROR_PAGE_ROUTE,
        Component: Error
    },
    {
        path: SAMPLE_PAGE_SOUTE,
        Component: SamplePage
    },
    {
        path: SEARCH_PAGE_ROUTE,
        Component: SearchPage
    },
    {
        path: EVENTS_PAGE_ROUTE,
        Component: EventsPage
    },
    {
        path: EVENT_DETAIL_PAGE_ROUTE,
        Component: EventDetail
    },
    {
        path: SERVICES_PAGE_ROUTE,
        Component: ServicesPage
    },
    {
        path: SERVICES_DETAIL_PAGE_ROUTE,
        Component: ServicesDetail
    },
    {
        path: PROJECTS_PAGE_ROUTE,
        Component: ProjectsPage
    },
    {
        path: PROJECTS_DETAIL_PAGE_ROUTE,
        Component: ProjectDetail
    },
    {
        path: WISHLIST_ROUTE,
        Component: WishListPage
    },
    {
        path: CATALOG_PAGE_ROUTE,
        Component: ProductsPage
    },
    {
        path: CATALOG_CATEGORY_PAGE_ROUTE,
        Component: Catalog
    },
    {
        path: ORDER_ERROR_PAGE_ROUTE,
        Component: OrderError
    },
    {
        path: USER_PAGE_ROUTE,
        Component: User
    },
]
export const privateRoutes = [

]