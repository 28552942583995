import React, { useContext, useEffect } from 'react'
import CatalogServices from '../../services/general/catalogServices';
import ProductCard from '../simple/ProductCard'
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Utils from '../../services/utils';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const ProductsGrid = observer(({item}) => {
    const {app, user} = useContext(Context);
    const [loading, setLoading] = useState();
    let query = new URLSearchParams(useLocation().search);

    const [categories, setCategories] = useState();

    const [items, setItems] = useState();
    const navigate = useNavigate();

    const fetchProducts = async () => {
        setLoading(true);
        let str = user.wishlistUid ? `favorite_uid=${user.wishlistUid}&` : '';
        let str2 = query.get('categories[]') ? query.toString() : '';

        const res = await CatalogServices.products.index(`${str}${str2}`);

        if(res.statusCode === 200){
            res.content.items && setItems(res.content.items);
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchProducts()
    }, [query.get('categories[]')])

    const fetachcategories = async () => {
        const res = item.ids ? await CatalogServices.categories.indexIds(item.ids) : await CatalogServices.categories.index();

        if((res.statusCode === 200) && res.content){
            res.content.items && setCategories(res.content.items)
        }
    }

    useEffect(() => {
        fetachcategories();
    }, [item])

    const setFilterData = (filterString) => {
        let newParams = new URLSearchParams(filterString);
        if (query.get('order')) {
            newParams.set('order', query.get('order'))
        }
    };

    const changeCategory = (name) => {
        let str = `?categories[]=${name}`;
        setFilterData(str);
        navigate({
            search: `?categories[]=${name}`
        });
    }

    const resetCategories = () => {
        setFilterData('');
        navigate({
            search: ``
        });
    }   

  return (
	<section className="product-section section-b-space">
		<div className="container-fluid-lg">
			<div className="title title-flex-2">
				<h2 className='new-title'>{item?.title}</h2>
				<ul className="nav nav-tabs tab-style-color tab-style-color-2" id="myTab">
                    <li className="nav-item">
                        <button type="button" className={!query.get('categories[]') ? "nav-link btn active" : "nav-link btn"} onClick={resetCategories}>{app.localizationsItems?.all_text}</button>
                    </li>
                    {categories?.length >=1 &&
                    categories.map((it, index) =>
                        <li className="nav-item" key={index}>
                            <button className={query.get('categories[]') === it.slug ? "nav-link btn active" : "nav-link btn"} onClick={() => changeCategory(it.slug)}
                                type="button">{Utils.trimText(it.title, 20, '...')}</button>
                        </li>
                    )}
				</ul>
			</div>

			<div className="tab-content" id="myTabContent">
				<div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                    <div className={loading ? "row g-8 isLoading" : "row g-8"}>
						{items?.map((card, index) =>
							<div className="col-xxl-2 col-lg-3 col-md-4 col-6 wow fadeInUp" key={index}>
								<ProductCard card={card} fetchItems={fetchProducts}/>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	</section>
  )
})

export default ProductsGrid