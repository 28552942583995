import React, { useContext, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useState } from 'react';
import Utils from '../../services/utils';
import useWindowResize from '../../hooks/useWindowResize.hook';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const PopupModal = observer(({item}) => {
    const {app} = useContext(Context);
    const {width} = useWindowResize();

    const [show, setShow] = useState(false)

    useEffect(() => {
        if(width < 767){
            if(!Utils.getCookie('popup-modal')){
                setShow(true);
                Utils.setCookie('popup-modal', 'true', {secure: true, 'max-age': 604800});
            }
        }
    }, [width])

    const handleClose = () => setShow(false);

  return (
    width < 767 &&
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            <div dangerouslySetInnerHTML={{__html: item.description}}></div>
            <button className='btn btn-animation proceed-btn fw-bold btn-md' onClick={handleClose}>{app.localizationsItems?.leave_modal}</button>
        </Modal.Body>
    </Modal>
  )
})

export default PopupModal