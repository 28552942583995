import React, { useContext, useEffect, useState } from 'react'
import Slider from 'react-slick'
import CatalogServices from '../../services/general/catalogServices'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'
import ProductCard2 from '../simple/ProductCard2'

const RecentViewProducts = observer(() => {
    const {app} = useContext(Context);
    const [loading, setLoading] = useState();
    const [items, setItems] = useState();
    
    const settings = {
        arrows: false,
        infinite: false,
        slidesToShow: 6,
        slidesToScroll: 3,
        dots: true,
        responsive: [{
                breakpoint: 1430,
                settings: {
                    slidesToShow: 5,
                    autoplay: true,
                    autoplaySpeed: 3500,
                }
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            },
        ]
    }

    const fetchProducts = async () => {
        setLoading(true);
        let str = ''
        let names = JSON.parse(localStorage.getItem("recent_products"));
        names?.forEach(element => {
            str += `ids[]=${element}&` 
        });
        const res = await CatalogServices.products.index(`${str}`);

        if(res.statusCode === 200){
            setItems(res.content);
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchProducts()
    }, [])

  return (
    localStorage.getItem("recent_products") &&
	<section className="product-list-section section-b-space">
		<div className="container-fluid-lg">
			<div className="title">
				<h2>{app.localizationsItems?.recent_products}</h2>
				<span className="title-leaf"></span>
			</div>
			<div className="row">
				<div className="col-12">
                    {items?.items &&
                        <Slider className="slider-6_1 product-wrapper" {...settings}>
                            {items.items.map((card, index) =>
                                <ProductCard2 item={card} key={index}/>
                            )}
                        </Slider>
                    }
                </div>
            </div>
        </div>
    </section>
  )
})

export default RecentViewProducts