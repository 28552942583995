export const PROJECTS_ROUTE = '/projects'
export const BLOG_ROUTE = '/articles'
export const SERVICES_ROUTE = '/services'
export const EVENTS_ROUTE = '/events'


export const MAIN_PAGE_ROUTE = '/:lang/*'
export const PROJECTS_PAGE_ROUTE = '/:lang/projects'
export const PROJECTS_DETAIL_PAGE_ROUTE = '/:lang/projects/:slug'
export const SERVICES_PAGE_ROUTE = '/:lang/services'
export const SERVICES_DETAIL_PAGE_ROUTE = '/:lang/services/:slug'

export const SAMPLE_PAGE_SOUTE = '/:lang/page/:slug'

export const PRODUCTS_PAGE_ROUTE = '/:lang/products'
export const PRODUCTS_DETAIL_PAGE_ROUTE = '/:lang/products/:slug'

export const CATALOG_PAGE_ROUTE = '/:lang/catalog'
export const CATALOG_CATEGORY_PAGE_ROUTE = '/:lang/catalog/:slug'


export const BLOG_PAGE_ROUTE = '/:lang/articles'
export const BLOG_DETAIL_PAGE_ROUTE = '/:lang/articles/:slug'


export const ABOUT_PAGE_ROUTE = '/:lang/about'

export const BLOG_RIGHT_SIDEVAR = "/blog/right-sidebar"
export const FAQ_PAGE_ROUTE = '/faq'


export const CONTACTS_PAGE_ROUTE = '/contacts'
export const EVENTS_PAGE_ROUTE = '/:lang/events'
export const EVENT_DETAIL_PAGE_ROUTE = '/:lang/events/:slug'


export const BASKET_PAGE_ROUTE = '/:lang/basket'
export const CHECKOUT_PAGE_ROUTE = '/:lang/checkout'
export const ORDER_SUCCESS_PAGE_ROUTE = '/:lang/order-success'
export const ORDER_ERROR_PAGE_ROUTE = '/:lang/order-error'

export const USER_PAGE_ROUTE = '/:lang/user/*'
export const USER_PROFILE_PAGE_ROUTE = '/:lang/user/profile'
export const USER_DASHBOARD_PAGE_ROUTE = '/:lang/user/dashboard'
export const USER_WISHLIST_ROUTE = '/:lang/user/wishList'
export const USER_ORDER_ROUTE = '/:lang/user/order'
export const USER_ADDRESS_ROUTE = '/:lang/user/address'
export const USER_CARDS_ROUTE = '/:lang/user/сards'
export const USER_PRIVACY_ROUTE = '/:lang/user/privacy'

export const LOGIN_PAGE_ROUTE = '/:lang/login'
export const REGISTRATION_PAGE_ROUTE = '/:lang/registration'
export const FORGOT_PASSWORD_ROUTE = '/:lang/forgot-password'

export const ERROR_PAGE_ROUTE = '/:lang/error'
export const SEARCH_PAGE_ROUTE = '/:lang/search'

export const WISHLIST_ROUTE = '/:lang/wishlist'