import React, { useContext, useEffect, useState } from 'react'
import DeliveryAddress from '../simple/DeliveryAddress'
import DeliveryOption from '../simple/DeliveryOption'
import PaymentMethod from '../simple/PaymentMethod'
import SummaryBoxCheckout from '../simple/SummaryBoxCheckout'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import UserServices from '../../services/general/userServices'
import CheckoutAddress from '../simple/CheckoutAddress'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'
import ReactInputMask from 'react-input-mask'
import { toast } from 'react-toastify'
import AuthServices from '../../services/general/authServices'
import OrderPhoneCheckModal from '../modals/OrderPhoneCheckModal'

const CheckoutContainer = observer(() => {
    const navigate = useNavigate();
    const {app, user} = useContext(Context);
    const [loading, setLoading] = useState();
    const [usedBonus, setUsedBonus] = useState();
    const [delivery, setDelivery] = useState();
    const [phoneCheckModal, setPhoneCheckModal] = useState();
    const [phone, setPhone] = useState();
    const [address, setAddress] = useState();

    const [orderData, setOrderData] = useState();

    const [deliveryZones, setDeliveryZones] = useState();

    const methods = useForm();

    const CustomSubmit = async (data) => {
        setLoading(true);
        const {address_id, ...rest} = data;

        let newData ={}

        if(user.token){
            newData= {
                ...rest,
                address_id,
                uid: user.uid,
                bonus_used: usedBonus
            }
        }else{
            newData= {
                ...rest,
                uid: user.uid,
                json_address: address?.json_address,
                full_address: address?.full_address,
                bonus_used: usedBonus
            }
        }
        
        if(app.registerByPhone && !user.token){
            const res = await AuthServices.logIn({phone: data.phone});

            if(res.statusCode === 200){
                setOrderData(newData);
                setPhone(data.phone);
                setPhoneCheckModal(true);
            }else{
                toast.error(res.message);
            }
        }else{
            const res = await UserServices.cart.order.order(newData);

            if(res.statusCode === 200){
                user.removeUid();
                if(res.content.user?.token){
                    user.setToken(res.content.user.token);
                }
                user.setBasketChange(Math.random().toString(16));
                if(res.content.checkout_url){
                    window.open(res.content.checkout_url, '_self');
                }else{
                    navigate(`/${app.lang}/order-success`)
                }
            }else if(res.statusCode === 502){
                user.removeUid();
                user.setBasketChange(Math.random().toString(16));
                navigate(`/${app.lang}/order-error`)
            }
            else{
                toast.error(res.message);
            }
        }
        
        setLoading(false);
    }

    const successCallback = async () => {
        setLoading(true);
        const res = await UserServices.cart.order.order(orderData);

        if(res.statusCode === 200){
            user.removeUid();
            if(res.content.user?.token){
                user.setToken(res.content.user.token);
            }
            user.setBasketChange(Math.random().toString(16));
            if(res.content.checkout_url){
                window.open(res.content.checkout_url, '_self');
            }else{
                navigate(`/${app.lang}/order-success`)
            }
        }else if(res.statusCode === 502){
            user.removeUid();
            user.setBasketChange(Math.random().toString(16));
            navigate(`/${app.lang}/order-error`)
        }
        else{
            toast.error(res.message)
        }
        setLoading(false);
    }

    const fetchDeliveryZones = async () => {
        let str = delivery ? `delivery_id=${delivery.id}` : '';
        const res = await UserServices.cart.order.deliveryZones(str);
        
        if(res.statusCode === 200){
            setDeliveryZones(res.content.items);
        }else{
            toast.error(res.message)
        }
    }

    useEffect(() => {
        if(delivery){
            fetchDeliveryZones(); 
        }
    }, [delivery])

  return (
	<section className="checkout-section-2 section-b-space">
        <FormProvider {...methods} >
            <form className={loading ? "container-fluid-lg isLoading" : "container-fluid-lg"} onSubmit={methods.handleSubmit(CustomSubmit)}>
                <div className="row g-sm-4 g-3">
                    <div className="col-lg-8">
                        <div className="left-sidebar-checkout">
                            <div className="checkout-detail-box">
                                <ul>
                                    <li>
                                        <DeliveryOption delivery={delivery} setDelivery={setDelivery}/>
                                    </li>
                                    {delivery?.slug === "delivery" ?
                                        user.token ?
                                            <li>
                                                <DeliveryAddress setAddress={setAddress} deliveryZones={deliveryZones}/>
                                            </li>
                                            :
                                            <li>
                                                <CheckoutAddress  address={address} setAddress={setAddress} deliveryZones={deliveryZones}/>
                                            </li>
                                        :
                                        delivery?.slug === "self" ?
                                            <li>
                                                {/*<DeliveryPoints/>*/}
                                            </li>
                                        :
                                        <></>
                                    }
                                    <li>
                                        <PaymentMethod/>
                                    </li>
                                    {!user.token && 
                                        <li>
                                            <div className="checkout-icon">
                                                <i className='fa-solid fa-user'/>
                                            </div>
                                            <div className='checkout-box'>
                                                <div className="checkout-title">
                                                    <h4>{app.localizationsItems?.user_data} <span className='red'>*</span></h4>
                                                </div>
                                                <div className="mb-3 custom-form">
                                                    <label>{app.localizationsItems?.name}</label>
                                                    <input type="text" className="form-control" required {...methods.register('name')} placeholder={app.localizationsItems?.name}/>
                                                </div>
                                                <div className="mb-3 custom-form">
                                                    <label>{app.localizationsItems?.email}</label>
                                                    <input type="email" className="form-control" required {...methods.register('email')} placeholder={app.localizationsItems?.email}/>
                                                </div>
                                                <div className="mb-3 custom-form">
                                                    <label>{app.localizationsItems?.phone}</label>
                                                    <Controller defaultValue="" name="phone" control={methods.control}
                                                            render={({field: {onChange, value}}) => (
                                                            <ReactInputMask onChange={onChange} value={value}
                                                                mask="+7(999)999-99-99" maskChar="_">
                                                                {
                                                                    inputProps => (
                                                                        <input type="tel" required className={methods.formState.errors.phone ? "form-control error" : "form-control"} placeholder={app.localizationsItems?.phone} {...inputProps}/>
                                                                    )
                                                                }
                                                            </ReactInputMask>
                                                            )}
                                                            rules={{pattern: {
                                                                value: /(?:\+|\d)[\d\-() ]{9,}\d/g
                                                            }, required: true}}
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                    }
                                    <li>
                                        <div className="checkout-icon">
                                            <i className='fa-solid fa-comments'/>
                                        </div>
                                        <div className='checkout-box'>
                                            <div className="checkout-title">
                                                <h4>{app.localizationsItems?.message}</h4>
                                            </div>
                                            <div className="mb-3 custom-form">
                                                <textarea type="text" className="form-control" {...methods.register('description')} placeholder={app.localizationsItems?.message}/>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <SummaryBoxCheckout usedBonus={usedBonus} setUsedBonus={setUsedBonus} address={address} delivery={delivery} deliveryZones={deliveryZones}/>
                    </div>
                </div>
            </form>
        </FormProvider>
        {phoneCheckModal && <OrderPhoneCheckModal show={phoneCheckModal} setShow={setPhoneCheckModal} phone={phone} successCallback={successCallback}/>}
	</section>
  )
})

export default CheckoutContainer