import React, { useContext, useEffect, useState } from 'react'
import UserServices from '../../../services/general/userServices';
import { useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import useFetchInfo from '../../../hooks/useFetchInfo.hook';
import { Context } from '../../..';
import { observer } from 'mobx-react-lite';
import MapComponent2 from '../../../components/complex/MapComponent2';
import { toast } from 'react-toastify';

const EditAddressModal = observer(({show, setShow, id, fetchItems, deliveryZones}) => {
    const [address, setAddress] = useState();

    const {app} = useContext(Context);

    const {info, loading} = useFetchInfo(UserServices.addresses.view, id);

    const handleClose = () => setShow(false);

    const {getValues, register, setValue, formState: {errors}} = useForm();

    useEffect(() => {
        if (info) {
            const {json_address} = info;
            for (const [key, value] of Object.entries(json_address)) {
                json_address[key] !== null && setValue(key, String(value));
            }
        }
    }, [info, setValue])

    const [center, setCenter] = useState();

    useEffect(() => {
        if(app.settings){
            let temp = app.settings.find(it => it.type === "map-center")?.value;
            temp = temp ? JSON.parse(temp) : null
            setCenter(temp ? temp : [51.1729850769043,71.42532348632812]);
        }
    }, [app.settings])

    const [apartmentError, setApartmentError] = useState();

    const CustomSubmit = async (data) => {
        if(!getValues('apartment')){
            setApartmentError(true)
            return
        }else{
            setApartmentError(false)
        }

        const newData = {
            json_address: {
                entrance: getValues('entrance') ? getValues('entrance') : null,
                floor: getValues('floor') ? getValues('floor') : null,
                apartment: getValues('apartment') ? getValues('apartment') : null,
                note: getValues('note') ? getValues('note') : null,
                address: addressValue,
                lat: center[0],
                lng: center[1]
            },
            full_address: addressValue
        }


        const res = await UserServices.addresses.update(id, newData);

        if (res.statusCode === 200) {
            fetchItems();
            toast.success(app.localizationsItems?.success_add_address);
            setShow(false);
        }else{
            toast.error(res.message)
        }
    };
    const [addressValue, setAddressValue] = useState('');

    const handleChange = (e) => {
        setAddressValue(e.target.value)
    }
  return (
	<Modal show={show} onHide={handleClose} className="modal fade theme-modal" centered size='xl'>
			<Modal.Header>
				<h5 className="modal-title">{app.localizationsItems?.edit_address}</h5>
				<div className="btn-close" onClick={handleClose}>
					<i className="fa-solid fa-xmark"></i>
				</div>
			</Modal.Header>
            <div>
                <Modal.Body>
                    <div className="row mob-column-reverse">
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <div className="col-12">
                                <div className="form-floating mb-4 theme-form-floating">
                                    <input value={addressValue} onChange={e => handleChange(e)} placeholder={app.localizationsItems?.address} className="form-control"></input>
                                    <label>{app.localizationsItems?.address}</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-floating mb-4 theme-form-floating">
                                    <input type="text" className={apartmentError ? "form-control error" : "form-control"} required {...register('apartment')} placeholder={app.localizationsItems?.apartment}/>
                                    <label>{app.localizationsItems?.apartment}</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-floating mb-4 theme-form-floating">
                                    <input type="text" className="form-control" {...register('entrance')} placeholder={app.localizationsItems?.entrance}/>
                                    <label>{app.localizationsItems?.entrance}</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-floating mb-4 theme-form-floating">
                                    <input type="text" className="form-control" {...register('floor')} placeholder={app.localizationsItems?.floor}/>
                                    <label>{app.localizationsItems?.floor}</label>
                                </div>
                            </div>
                            <div className="form-floating mb-4 theme-form-floating">
                                <textarea type="text" className="form-control" {...register('note')} placeholder={app.localizationsItems?.note}/>
                                <label>{app.localizationsItems?.note}</label>
                            </div>
                            <div className="col-12">
                                <div className="d-flex">
                                    <div onClick={CustomSubmit} className="btn theme-bg-color btn-md text-white">{app.localizationsItems?.save}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <div className='map-container'>
                                <MapComponent2 setAddress={setAddress} info={info} deliveryZones={deliveryZones} setAddressValue={setAddressValue}/>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </div>
	</Modal>
  )
})

export default EditAddressModal