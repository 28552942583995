import React, { useContext } from 'react'
import ProductLeftSidebar from '../../components/complex/ProductLeftSidebar'
import ProductNav from '../../components/complex/ProductNav'
import PageTop from '../../components/simple/PageTop'
import RelatedProducts from '../../components/simple/RelatedProducts'
import useFetchInfo from '../../hooks/useFetchInfo.hook'
import Advantages from '../../components/complex/Advantages'
import { useNavigate, useParams } from 'react-router-dom'
import CatalogServices from '../../services/general/catalogServices'
import { useEffect } from 'react'
import AboutUs from '../../components/complex/AboutUs'
import OurTeam from '../../components/complex/OurTeam'
import ReviewSection from '../../components/complex/ReviewSection'
import FaqComponent from '../../components/complex/FaqComponent'
import OurBusiness from '../../components/complex/OurBusiness'
import Excellences from '../../components/complex/Excellences'
import BannerSection from '../../components/simple/BannerSection'
import BannerSection2 from '../../components/simple/BannerSection2'
import Newsletter from '../../components/complex/Newsletter'
import DayOffer from '../../components/complex/DayOffer'
import ProductsGrid from '../../components/complex/ProductsGrid'
import OurBlog from '../../components/complex/OurBlog'
import { useState } from 'react'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'
import RecentViewProducts from '../../components/complex/RecentViewProducts'

const ProductDetail = observer(() => {
    const {slug} = useParams();
    const {app, user} = useContext(Context);
    const navigate = useNavigate();
    const{lang: lang2} = useParams();
    const [ids, setIds] = useState();
    
    const [info, setInfo] = useState();
    const [loading, setLoading] = useState();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2){
                navigate(`/${app.lang}/products/${slug}`);
            }
        }
    }, [app.lang])

    const fetchInfo = async () => {
        setLoading(true);
        let str = user.wishlistUid ? `favorite_uid=${user.wishlistUid}&` : '';

        const res = await CatalogServices.products.view(slug, str);

        if(res.statusCode === 200){
            setInfo(res.content);
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchInfo();
    }, [slug, app.lang])

    useEffect(() => {
        if(info){
            if(info.related_products){
                let str = ''
                info.related_products.forEach(element => {
                    str += `ids[]=${element}&` 
                });
                setIds(str);
            }
        }
    }, [info])

    useEffect(() => {
        if(info){
            let names = localStorage.getItem("recent_products") ? JSON.parse(localStorage.getItem("recent_products")) : [];
            if(names.length >= 12){
                names.splice(0, 1);
            }
            if(!names.find(it => it === info.id)){
                names.push(info.id);
            }
            localStorage.setItem('recent_products', JSON.stringify(names))
        }
    }, [info])

  return (
    <>
    {loading ?
        <div className="fullpage-loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        :
        info &&
            <div>
                <PageTop item={info}/>
                <ProductLeftSidebar item={info} fetchInfo={fetchInfo}/>
                <ProductNav item={info}/>
                {ids && <RelatedProducts ids={ids}/>}
                <RecentViewProducts/>
                {info?.elements?.map((elem, index) =>
                    <div key={index}>
                        {elem.type === "about" ?
                            <AboutUs elem={elem}/>
                            :
                        elem.type === "advantages" ?
                            <Advantages item={elem}/>
                            :
                        elem.type === "workers" ?
                            <OurTeam elem={elem}/>
                            :
                        elem.type === "reviews" ?
                            <ReviewSection elem={elem}/>
                            :
                        elem.type === "faq" ?
                            <FaqComponent elem={elem}/>
                            :
                        elem.type === "advantages-2" ?
                            <OurBusiness elem={elem}/>
                            :
                        elem.type === "steps" ?
                            <Excellences elem={elem}/>
                            :
                        elem.type === "articles" ?
                            <OurBlog elem={elem}/>
                            :
                        elem.type === "advertising-banner-2" ?
                            Number(elem.banner_type) === 1 ?
                                <BannerSection item={elem}/>
                                :
                            Number(elem.banner_type) === 2 ?
                                <BannerSection2 item={elem}/>
                                :
                            Number(elem.banner_type) === 3 ?
                                <Newsletter item={elem}/>
                                :
                                <></>
                            :
                        elem.type === "products" ?
                            elem.products_type === "recommended" ?
                                <DayOffer item={elem}/>
                                :
                                <ProductsGrid item={elem}/>
                            :
                            <></>
                        }
                    </div>
                )}
            </div>
    }
    </>
  )
})

export default ProductDetail