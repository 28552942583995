import React from 'react'

const TextComponent = ({elem}) => {
  return (
    <section className='blog-section section-b-space'>
        <div className="container-fluid-lg">
            <div dangerouslySetInnerHTML={{__html: elem.description}}></div>
        </div>
    </section>
  )
}

export default TextComponent