import React from 'react'
import img from "../../images/placeholder.png"

const BannerTop = ({card}) => {
  return (
    <div className="banner-contain-2 hover-effect">
        <img src={card.detail_image} className="bg-img rounded-3 bg-img-abs" alt=""
            onError={({ currentTarget }) => {
                currentTarget.onerror = null; 
                currentTarget.src = img
            }}
        />
        <div className="banner-detail p-center-right position-relative shop-banner ms-auto banner-small">
            <div>
                <h2>{card.subtitle}</h2>
                <h3 dangerouslySetInnerHTML={{__html: card.description}}></h3>
            </div>
        </div>
    </div>
  )
}

export default BannerTop