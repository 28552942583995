import React, { useContext } from 'react'
import Slider from "react-slick";
import CatalogServices from '../../services/general/catalogServices';
import { NavLink } from 'react-router-dom';
import img from "../../images/placeholder.png"
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import { useEffect } from 'react';

const CategoriesSlider = observer(({item}) => {
    const {app, productCategories} = useContext(Context);

    const settings = {
        arrows: true,
        infinite: false,
        slidesToShow: 8,
        slidesToScroll: 1,
        responsive: [{
                breakpoint: 1745,
                settings: {
                    slidesToShow: 7,
                }
            },
            {
                breakpoint: 1399,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 1124,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 692,
                settings: {
                    slidesToShow: 3,
                }
            }, {
                breakpoint: 482,
                settings: {
                    slidesToShow: 2,
                }
            },
        ]
    }

    const fetachcategories = async () => {
        if(productCategories.categories.length < 1){
            const res = await CatalogServices.categories.index();

            if(res.statusCode === 200){
                productCategories.setCategories(res.content.items);
            }
        }
    }

    useEffect(() => {
        fetachcategories();
    }, [productCategories.categories])

  return (
    productCategories.categories.length >= 1 &&
	<section className="category-section-2">
		<div className="container-fluid-lg">
			<div className="title">
				<h2>{item?.title}</h2>
			</div>
            {item.is_slider ?
                <div className="row">
                    <div className="col-12">
                            <Slider className="category-slider arrow-slider" {...settings}>
                                {productCategories.categories.map((card, index) =>
                                    <div key={index}>
                                        <div className="shop-category-box border-0 wow fadeIn">
                                            <NavLink to={`/${app.lang}/catalog/${card.slug}`} className="circle-1">
                                                <img src={card.image} className="img-fluid" alt={card.title}
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null; 
                                                        currentTarget.src = img
                                                    }}
                                                />
                                            </NavLink>
                                            <div className="category-name">
                                                <h6>{card.title}</h6>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Slider>
                    </div>
                </div>
                :
                <div className="row">
                    <div className="col-12">
                        <div className="slider-9">
                            {productCategories.categories.map((card, index)=>
                                <div key={index}>
                                    <NavLink to={`/${app.lang}/catalog/${card.slug}`} className="category-box wow fadeInUp">
                                        <div>
                                            <img src={card.image} alt={card.title}
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null; 
                                                    currentTarget.src = img
                                                }}
                                            />
                                            <h5>{card.title}</h5>
                                        </div>
                                    </NavLink>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            }
		</div>
	</section>
  )
})

export default CategoriesSlider