import http from "./http-common"
import {deleteData, getData, postData} from "../handlers";

export default class UserServices {
    static cart = {
        index: (uid) => {
            return getData(http, `/app/user/cart${uid ? `?uid=${uid}` : ""}`);
        },

        addToCart: (data) => {
            return postData(http, `/app/user/cart/addToCart`, data);
        },

        update: (data) => {
            return postData(http, `/app/user/cart/updateCart`, data);
        },

        remove: (data) => {
            return postData(http, `/app/user/cart/removeFromCart`, data);
        },

        clear: (data) => {
            return postData(http, `/app/user/cart/clear`, data);
        },

        order: {
            deliveries: () => {
                return getData(http, `/app/user/cart/deliveries`);
            },

            payments: () => {
                return getData(http, `/app/user/cart/payments`);
            },

            order: (data) => {
                return postData(http, `/app/user/cart/order`, data);
            },

            deliveryZones: (queryString) => {
                return getData(http, `/app/user/cart/deliveryZones${queryString ? `?${queryString}` : ""}`);
            },

            pickupAddresses: () => {
                const temp = JSON.parse(localStorage.getItem('city'));
                return getData(http, `/app/user/cart/pickupAddresses${temp?.value ? `?city_id=${temp.value}` : ''}`);
            },
        }
    }

    static favorites = {
        index: (uid) => {
            return getData(http, `/app/user/favorites${uid ? `?uid=${uid}` : ""}`);
        },

        toggle: (data) => {
            return postData(http, `/app/user/favorites/toggle`, data);
        },
    }

    static profile = {
        index: () => {
            return getData(http, `/app/user/profile`);
        },

        balanceHistory: () => {
            return getData(http, `/app/user/profile/balanceHistory`);
        },

        update: (data) => {
            return postData(http, `/app/user/profile`, data);
        },

        delete: () => {
            return deleteData(http, `/app/user/profile`);
        },
    }

    static addresses = {
        index: () => {
            return getData(http, `/app/user/addresses`);
        },

        view: (id) => {
            return getData(http, `/app/user/addresses/${id}`);
        },

        store: (data) => {
            return postData(http, `/app/user/addresses/store`, data);
        },

        update: (id, data) => {
            return postData(http, `/app/user/addresses/${id}`, data);
        },

        delete: (id) => {
            return deleteData(http, `/app/user/addresses/${id}`,);
        },
    }

    static orders = {
        index: () => {
            return getData(http, `/app/user/orders`);
        },

        view: (id) => {
            return getData(http, `/app/user/orders/${id}`);
        },

        toggle: (id) => {
            return getData(http, `/app/user/orders/${id}`);
        },
    }
}