import React, { useContext } from 'react'
import { useState } from 'react'
import Address from '../component/Address'
import Dashboard from '../component/Dashboard'
import Order from '../component/Order'
import WishList from '../component/WishList'
import UserDashboard from '../../../components/complex/UserDashboard'
import { Route, Routes } from 'react-router-dom'
import OrderProductDetail from './OrderProductDetail'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'
import Bonuses from './Bonuses'

const UserContainer = observer(() => {
    const {app} = useContext(Context);

    const [visibleOrder, setVisibleOrder] = useState();

    const [show, setShow] = useState();

  return (
	<section className="user-dashboard-section section-b-space">
		<div className="container-fluid-lg">
			<div className="row">
				<div className="col-xxl-3 col-lg-4">
					<UserDashboard show={show} setShow={setShow} setVisibleOrder={setVisibleOrder}/>
				</div>

				<div className="col-xxl-9 col-lg-8">
						<button className="btn left-dashboard-show btn-animation btn-md fw-bold d-block mb-4 d-lg-none" 
                            onClick={() => setShow(true)}>{app.localizationsItems?.open_dashboard}</button>
						<div className="dashboard-right-sidebar">
							<Routes>
                                <Route index element={<Dashboard />} />
								<Route path='dashboard' element={<Dashboard/>}/>
                                <Route path='wishlist' element={<WishList/>}/>
								<Route path='orders' element={<Order visibleOrder={visibleOrder} setVisibleOrder={setVisibleOrder}/>}/>
								{app.bonuses && <Route path='bonuses' element={<Bonuses/>}/>}
								<Route path='addresses' element={<Address/>}/>
                                <Route path='orders/:id' element={<OrderProductDetail/>}/>
							</Routes>
						</div>
				</div>
			</div>
		</div>
        <div className={show ? 'bg-overlay show' : 'bg-overlay'} onClick={() => setShow(false)}></div>
	</section>
  )
})

export default UserContainer